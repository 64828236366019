<template>
  <section class="post-items-slider overflow dosiin_style-pc">
    <Hashtag v-if="layout == 3"></Hashtag>
    <div class="dosiin_swiper-container-post">
      <PostSkeleton v-if="!dosiinEmptyObject(state.skeleton)"></PostSkeleton>
      <div v-else class="post-group overflow black-post">
        <PostHeader :post="state.post" :show_back_button="true" />
        <div class="dosiin_post-img-slider post-img-slider overflow">
          <img v-lazy="_postImage(state.post.main_pair)" class="dosiin_w-100" />
        </div>
        <div class="content">
          <div class="tag-link" v-if="state.post.tags">
            <router-link
              v-for="(tag, key) in state.post.tags"
              :to="{ name: 'tags', query: { tag: tag.tag } }"
              v-bind:key="key"
              >#{{ $filters.trim(tag.tag) }}&nbsp;
            </router-link>
          </div>
          <section id="firstContent">
            <div class="desc" v-text="state.post.short_description"></div>
          </section>
          <div class="dosiin_product-list product-list">
            <Swiper
              :observer="true"
              :observeParents="true"
              :observeSlideChildren="true"
              :spaceBetween="9"
              :slidesPerView="1.2"
            >
              <Swiper-slide
                v-for="(product, p_id) in state.post.products"
                v-bind:key="p_id"
                :class="
                  Object.keys(state.post.products).length == 1 ? 'fit-box-product' : ''
                "
              >
                <div class="list-item product-list-item">
                  <router-link
                    class="list-item_img"
                    :to="_productUrl(product)"
                    :title="product.product"
                  >
                    <img
                      class="product-sm-item_img"
                      v-lazy="_postImage(state.post.main_pair)"
                      :alt="product.product"
                      :title="product.product"
                      width="76"
                      height="76"
                    />
                  </router-link>
                  <dl class="list-item_summary">
                    <div class="product-list-item_name_wrapper">
                      <dd class="list-item_name">
                        <router-link
                          class="list-item_product"
                          :to="_productUrl(product)"
                          :title="product.product"
                        >
                          {{ product.product }}</router-link
                        >
                      </dd>
                    </div>
                    <div class="list-item_sale product-list-item_price_wrapper">
                      <dd class="list-item_price">{{ currencyFormat(product.price) }}</dd>
                      <dd
                        class="inline-bl list-item_sale_origin"
                        v-if="product.list_price > product.price"
                      >
                        {{ currencyFormat(product.list_price) }}
                      </dd>
                      <dd
                        class="inline-bl list-item_sale_discount"
                        v-if="discountPrc(product) > 0"
                      >
                        {{ discountPrc(product) }}%
                      </dd>
                    </div>
                  </dl>
                </div>
              </Swiper-slide>
            </Swiper>
          </div>

          <section v-if="state.post.categories_brands">
            <div class="dosiin-tag-properties">
              <dl
                class="tag-list-item"
                v-for="(cat_with_name, index) in state.post.categories_brands_with_name"
                v-bind:key="index"
              >
                <dt class="tag-icon dosiin_mr-2">
                  <i :class="['dsi', getCategoryIcon(index)]"></i>
                </dt>
                <dd class="tag-name">
                  <div class="dosiin_hashtag_slider swiper-tag-name-detail-feed">
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide"
                        v-for="(brand_name, i) in cat_with_name.brand_name"
                        :key="i"
                      >
                        <a href="#" :title="brand_name">
                          <data class="tag-name-item dosiin_mr-1">#{{ brand_name }}</data>
                        </a>
                      </div>
                    </div>
                  </div>
                </dd>
              </dl>
            </div>
          </section>

          <section>
            <div class="section-wrappers dosiin-comment-feed-detail-warpper">
              <div class="section-title-wrapper">
                <h2 class="section-title">Thảo luận bài viết</h2>
              </div>
              <template v-if="discussions.posts && discussions.posts.length">
                <div class="section-title-wrapper">
                  <h2 class="section-title">
                    <b>{{ state.post.discussion_count }}</b> thảo luận
                  </h2>
                  <button
                    class="dosiin_comment-sort comment-sort-btn d-flex align-items-center justify-content-between d-none"
                  >
                    Mới - Cũ<i class="dsi dsi-arrow-down dosiin_ml-2"></i>
                  </button>
                </div>

                <div class="comment-feed-detail-warpper customer-review-section">
                  <div
                    class="review-item review-item-product list-comment-feed-detail"
                    v-for="(discussion, i) in discussions.posts"
                    :key="i"
                  >
                    <div class="review-item-info">
                      <router-link
                        :to="{
                          name: 'profile',
                          query: { profile_id: discussion.user_id },
                        }"
                        class="link-item-product"
                      >
                        <img
                          v-lazy="_userAvatar(discussion.user_avatar)"
                          class="user-avatar"
                          width="40"
                          height="40"
                        />
                      </router-link>
                      <div class="rating-info">
                        <h3 class="user-name truncate" v-text="discussion.name"></h3>
                        <div class="rating-with-time">
                          <span
                            class="rating-time-product"
                            v-text="_momentFromNow(discussion.timestamp)"
                          ></span>
                        </div>
                        <div class="review-item-preview dosiin_mt-2">
                          <div class="review-item-preview_left">
                            <div class="review-item-description">
                              <span v-text="discussion.message"></span>
                            </div>
                          </div>
                        </div>
                        <div class="review-item-action">
                          <div class="review-item-action-btn">
                            <LikeButton
                              likeable_type="discussion"
                              :likeable_id="discussion.post_id"
                              :isLiked="discussion.is_like"
                            />

                            <button
                              class="more-btn dosiin_reply-btn comment"
                              @click="
                                $notify({
                                  type: 'vv',
                                  title: 'Thông báo',
                                  text: 'Tính năng đang phát triển.',
                                })
                              "
                            >
                              <i class="dsi dsi-more-h"></i>
                            </button>
                          </div>
                          <div class="review-item-action-info">
                            <span class="info-like"
                              >{{ discussion.like_count }} lượt thích</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div class="no_comments-inner" v-else>
                <div class="no_comments" style="text-align: center">
                  <img :src="_emptyReview" />
                  <p>Bài viết chưa có thảo luận</p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="link-group-action sticky" id="dosiin_footer-sticky">
          <CommentPost />
          <hr class="line" />
          <div class="review-item-action align-items-center">
            <div class="review-item-action-btn">
              <LikeButton
                likeable_type="vnfd"
                :likeable_id="state.post.vnfd_id"
                :isLiked="state.post.is_like"
                :indexPost="'post_' + state.post.vnfd_id"
              />
              <CommentButton
                isType="comment"
                :vnfd_id="state.post.vnfd_id"
                :url="state.post.seo_name"
              />

              <a
                class="social-interact-btn dosiin_social-interact-btn share"
                @click="sharePost(state.post)"
              >
                <i class="dsi dsi-share"></i>
              </a>
            </div>
            <div class="review-item-action-info">
              <span class="info-like"
                ><b>{{ state.post.like_count }} </b>&nbsp;lượt thích</span
              >
              <span class="dosiin_mr-2 dosiin_ml-2"> • </span>
              <span class="info-reply"
                ><b>{{ state.post.discussion_count }} </b>&nbsp;bình luận</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <ShareButtonModal ref="modalShare" />

    <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="pswp__bg"></div>
      <div class="pswp__scroll-wrap">
        <div class="pswp__container">
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
        </div>
        <div class="pswp__ui pswp__ui--hidden">
          <div class="pswp__top-bar">
            <div class="pswp__counter"></div>
            <button
              class="pswp__button pswp__button--close d-block"
              style="display: block"
              title="Close (Esc)"
            ></button>
            <button
              class="pswp__button pswp__button--share d-block"
              style="display: block"
              title="Share"
            ></button>
            <button
              class="pswp__button pswp__button--zoom d-block"
              style="display: block"
              title="Zoom in/out"
            ></button>
            <div class="pswp__preloader">
              <div class="pswp__preloader__icn">
                <div class="pswp__preloader__cut">
                  <div class="pswp__preloader__donut"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div class="pswp__share-tooltip"></div>
          </div>

          <button
            class="pswp__button pswp__button--arrow--left"
            title="Previous (arrow left)"
          ></button>
          <button
            class="pswp__button pswp__button--arrow--right"
            title="Next (arrow right)"
          ></button>
          <div class="pswp__caption">
            <div class="pswp__caption__center dosiin_text-center"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { inject, ref } from "vue";
import CommentPost from "@/components/forms/CommentForm.vue";
import PostHeader from "@/components/post/Header.vue";
import PostSkeleton from "@/components/skeletons/PostLoader.vue";
import Hashtag from "@/components/hashtag/HashTag.vue";
export default {
  components: {
    CommentPost,
    PostHeader,
    PostSkeleton,
    Hashtag,
  },
  setup() {
    const state = inject("state");
    const discussions = inject("discussions");
    const modalShare = ref(null);
    const sharePost = (post, event) => {
      modalShare.value.updatePost(post, event);
    };
    return {
      state,
      modalShare,
      sharePost,
      discussions,
    };
  },
};
</script>

<style>
.desc img {
  width: 100% !important;
  height: auto;
}
.news-title {
  font-weight: 700;
  font-size: 1.6em;
  line-height: 1.2em;
  margin: 0;
  padding-top: 30px;
  margin-bottom: 1.2rem;
}

#firstContent {
  clear: both;
  display: block;
  visibility: visible;
  opacity: 1;
  position: relative;
  line-height: 30px;
}
</style>
