<template>
    <div class="ds_post-list">
        <div class="ds_post-item" v-for="i in 6" :key="i">
            <div class="post-group overflow">
                <div class="dosiin_post-img-slider post-img-slider overflow">
                    <Skeletor height="357" />
                </div>
                <div class="post-group-info post-group-mr">
                    <div class="top-content">
                        <div class="header-post">
                            <div class="avatar">
                                <a>
                                    <Skeletor circle width="40" height="40" />
                                </a>
                                <div class="detail-avatar">
                                    <div class="name">
                                        <Skeletor width="40%" />
                                    </div>
                                    <div class="time">
                                        <Skeletor width="25%" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tag-link">
                            <Skeletor width="100%" />
                            <br/>
                            <Skeletor width="100%" />
                        </div>
                        <div class="desc">
                            <Skeletor width="100%" />
                        </div>
                    </div>
                    <div class="action-group">
                        <Skeletor width="100%" />
                    </div>
                    <hr class="line">
                    <div class="link-action">
                        <Skeletor width="30%" />
                        <Skeletor width="30%" />
                        <Skeletor width="30%" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>