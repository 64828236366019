<template>
  <PostMagazine v-if="state.post && state.post.type == 'm'" />
  <PostStyle v-else />
</template>

<script>
import PostMagazine from "@/pages/pc/post/Magazine";
import PostStyle from "@/pages/pc/post/Style";
import { inject } from "vue";
export default {
  components: {
    PostMagazine,
    PostStyle,
  },
  setup() {
    const state = inject("state");
    return {
      state,
    };
  },
};
</script>
