<template>
  <Intersection @onIntersection="onIntersection">
    <div class="ds_post-product related-post">
      <div class="header-section">
        <h2 class="header-section-left-side section-title">Bài viết khác</h2>
        <div class="header-section-right-side"></div>
      </div>

      <RelatedPostSkeleton v-if="loading" />
      <div v-else class="ds_post-list">
        <div class="ds_post-item" v-for="(post, i) in posts" :key="i">
          <div class="post-group overflow">
            <div class="dosiin_post-img-slider post-img-slider overflow">
              <Attach :post="post" />
              <Swiper
                :modules="modules"
                :pagination="RelatedPostImagePostSlide.pagination"
                :slidesPerView="RelatedPostImagePostSlide.slidesPerView"
                :observer="RelatedPostImagePostSlide.observer"
                :observeParents="RelatedPostImagePostSlide.observeParents"
                :observeSlideChildren="RelatedPostImagePostSlide.observeSlideChildren"
                :slideToClickedSlide="RelatedPostImagePostSlide.slideToClickedSlide"
                :watchSlidesVisibility="RelatedPostImagePostSlide.watchSlidesVisibility"
                :watchSlidesProgress="RelatedPostImagePostSlide.watchSlidesProgress"
                :breakpoints="RelatedPostImagePostSlide.breakpoints"
                @click="layout != 4 ? showDetailPost(post) : ''"
              >
                <Swiper-slide>
                  <div class="img-post-wrapper">
                    <router-link :to="_vnfdUrl(post.seo_name)" v-if="layout == 4">
                      <img
                        v-lazy="_postImage(post.main_pair)"
                        class="load_scroll cm-image pict dosiin_w-100"
                        width="670"
                        height="670"
                      />
                    </router-link>
                    <img
                      v-else
                      v-lazy="_postImage(post.main_pair)"
                      class="load_scroll cm-image pict dosiin_w-100"
                      width="670"
                      height="670"
                    />
                  </div>
                </Swiper-slide>
                <Swiper-slide v-for="(img, i) in post.addition_images" :key="i">
                  <div class="img-post-wrapper">
                    <router-link :to="_vnfdUrl(post.seo_name)" v-if="layout == 4">
                      <img
                        v-lazy="_postImageWithFullUrl(img)"
                        class="dosiin_w-100"
                        width="670"
                        height="670"
                      />
                    </router-link>
                    <img
                      v-else
                      v-lazy="_postImageWithFullUrl(img)"
                      class="dosiin_w-100"
                      width="670"
                      height="670"
                    />
                  </div>
                </Swiper-slide>
                <div class="swiper-pagination"></div>
                <div
                  v-if="layout != 4"
                  class="swiper-button-prev dosiin_swiper-button-gradient"
                ></div>
                <div
                  v-if="layout != 4"
                  class="swiper-button-next dosiin_swiper-button-gradient"
                ></div>
              </Swiper>
            </div>
            <div class="post-group-info post-group-mr">
              <div class="top-content">
                <PostHeader :post="post" :show_back_button="false" />
                <Tags :post="post" />
                <div class="desc" v-text="post.short_description"></div>
                <!-- <div class="dosiin_desc-style desc" v-html="post.description"></div> -->
              </div>
              <div class="action-group">
                <div class="like-desc">
                  <span>
                    <b>{{ post.like_count }} </b>&nbsp;lượt thích
                  </span>
                  <span class="dosiin_ml-1 dosiin_mr-1"> • </span>
                  <span>
                    <b>{{ post.discussion_count }} </b>&nbsp;bình luận</span
                  >
                </div>
                <div class="share-desc">
                  <b>{{ post.share_count }} </b>&nbsp;lượt chia sẻ
                </div>
              </div>
              <hr class="line" />
              <div class="link-action">
                <LikeButton
                  type="text"
                  likeable_type="vnfd"
                  :likeable_id="post.vnfd_id"
                  :isLiked="post.is_like"
                  :indexPost="'post_' + post.vnfd_id"
                />

                <CommentButton
                  isType="link"
                  :vnfd_id="post.vnfd_id"
                  :url="post.seo_name"
                />
                <button class="share_dropdow" @click="sharePostDropdown(post, $event)">
                  <i class="dsi dsi-share"></i>
                  <span>Chia sẻ</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ShareButtonDropdown ref="dropdownShare" />
    <PostDetailModal
      v-if="layout !== 4"
      ref="modalPostDetail"
      v-model="showDetailModal"
    />
  </Intersection>
</template>

<script>
import StyleService from "@/services/style";
import Attach from "@/components/post/Attach.vue";
import PostHeader from "@/components/post/Header.vue";
import Tags from "@/components/post/Tags.vue";
import { Navigation, Pagination } from "swiper";
import { ref } from "vue";
import { useStore } from "vuex";
import { _addLeadingZero } from "@/utlis/helpers";
import RelatedPostSkeleton from "@/components/skeletons/post/Releated";
import PostDetailModal from "@/components/modals/DetailPostStyle";

export default {
  components: {
    Attach,
    PostHeader,
    Tags,
    RelatedPostSkeleton,
    PostDetailModal,
  },
  props: {
    post: {
      type: Object,
      default: {},
    },
    relatedType: {
      type: String,
      default: "post",
    },
  },
  setup(props) {
    const RelatedPostImagePostSlide = {
      slidesPerView: "auto",
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      slideToClickedSlide: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
        formatFractionCurrent: (number) => _addLeadingZero(number),
        formatFractionTotal: (number) => _addLeadingZero(number),
      },
      breakpoints: {
        769: {
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        },
      },
    };
    const posts = ref([]);
    const store = useStore();
    const dropdownShare = ref(null);
    const loading = ref(true);
    const showDetailModal = ref(false);
    const modalPostDetail = ref(false);

    const onIntersection = () => {
      if (props.relatedType === "post") {
        var params = {
          items_per_page: 6,
          limit: 6,
          sort_by: "random",
        };

        if (props.post.brand_id) {
          params.brand_id = props.post.brand_id;
        }

        if (props.post.user_id) {
          params.user_id = props.post.user_id;
        }

        getPosts(params);
      }

      if (props.relatedType === "product") {
      }
    };

    async function getPosts(params) {
      const response = await StyleService.fetchPosts(params);
      posts.value = response.data.vnfd;
      // push to state
      store.dispatch("PostsStore/addPosts", { postData: response.data.vnfd });
      loading.value = false;
    }

    function sharePostDropdown(post, event) {
      dropdownShare.value.updatePost(post, event);
    }

    function showDetailPost(post) {
      modalPostDetail.value.detailPost(post);
    }

    return {
      loading,
      posts,
      dropdownShare,
      RelatedPostImagePostSlide,
      sharePostDropdown,
      modules: [Navigation, Pagination],
      onIntersection,
      modalPostDetail,
      showDetailPost,
      showDetailModal,
    };
  },
};
</script>
