<template>
  <main class="dosiin_home home-page main-page style-detail-post-mag">
    <section class="post-items-slider">
      <div class="post-group black-post">
        <div
          class="mag-header header-post d-flex align-items-center justify-content-between"
        >
          <div class="header-mag-left d-flex align-items-center">
            <div class="tag-post dosiin_mr-2">Tin tức</div>
            <div class="time d-flex">
              <i class="dsi dsi-s16 dsi-clock dosiin_mr-1"></i
              ><span v-text="_momentDayToDateString(state.post.timestamp)"></span>
            </div>
          </div>
          <button class="close-mag" type="button" @click="$router.push('/style')">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_11290_52179)">
                <path
                  d="M4.42144 16.4722L9.99967 10.894L15.5779 16.4722C15.6362 16.5305 15.714 16.5888 15.7917 16.6083C15.8694 16.6471 15.9472 16.6666 16.0249 16.6666C16.1027 16.6666 16.1999 16.6471 16.2776 16.6277C16.3554 16.5888 16.4331 16.55 16.4914 16.4917C16.5497 16.4333 16.5886 16.3556 16.6275 16.2779C16.6663 16.2001 16.6663 16.1224 16.6663 16.0252C16.6663 15.9474 16.6469 15.8503 16.608 15.7919C16.5692 15.7142 16.5303 15.6559 16.472 15.5976L10.8937 9.99992L16.472 4.42169C16.5692 4.32451 16.6275 4.22732 16.6469 4.09127C16.6663 3.97465 16.6663 3.8386 16.608 3.72198C16.5497 3.60536 16.472 3.50818 16.3748 3.43043C16.2776 3.37212 16.1416 3.33325 16.0249 3.33325C15.85 3.33325 15.6945 3.411 15.5779 3.52762L9.99967 9.10585L4.42144 3.52762C4.36313 3.46931 4.28539 3.411 4.20764 3.39156C4.1299 3.35269 4.05215 3.33325 3.95497 3.33325C3.83835 3.33325 3.72173 3.37212 3.60512 3.44987C3.50793 3.50818 3.43019 3.60536 3.39132 3.72198C3.35244 3.8386 3.33301 3.97465 3.35244 4.09127C3.37188 4.20789 3.43019 4.32451 3.52737 4.42169L9.1056 9.99992L3.52737 15.5781C3.46906 15.6365 3.41075 15.7142 3.39132 15.7919C3.35244 15.8697 3.33301 15.9474 3.33301 16.0252C3.33301 16.1029 3.35244 16.2001 3.37188 16.2779C3.39132 16.3556 3.44963 16.4333 3.50793 16.4917C3.56624 16.55 3.64399 16.5888 3.72173 16.6277C3.79948 16.6666 3.87723 16.6666 3.97441 16.6666C4.05215 16.6666 4.14933 16.6471 4.20764 16.6083C4.28539 16.5694 4.36313 16.5305 4.42144 16.4722Z"
                  fill="#E6E6E6"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_11290_52179">
                  <rect
                    width="13.3333"
                    height="13.3333"
                    fill="white"
                    transform="translate(3.33301 3.33325)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div class="content">
          <h1 class="title" v-text="state.post.title"></h1>
          <div class="maz-description" v-html="state.post.description"></div>
          <div class="img-post"></div>
          <img
            src="https://dosi-in.com/file/vnfd/387/dosiin-260109387570.jpg?w=670&h=670&fit=crop&fm=webp"
            alt="post images"
            width="100%"
            height="203"
          /><img
            src="https://dosi-in.com/file/vnfd/387/dosiin-260109387570.jpg?w=670&h=670&fit=crop&fm=webp"
            alt="post images"
            width="100%"
            height="203"
          />
          <div class="author-mag_wrapper" v-if="state.post.user_id != 0">
            <div class="author-mag_info d-flex align-items-center">
              <div class="author-mag_img">
                <router-link
                  :to="{ name: 'profile', query: { profile_id: state.post.user_id } }"
                >
                  <img
                    width="40"
                    height="40"
                    v-lazy="{
                      src: _userAvatar(state.author.avatar),
                      loading: _userDefaultAvatar,
                      error: _userDefaultAvatar,
                    }"
                  />
                </router-link>
              </div>
              <div class="author-mag_info dosiin_ml-2">
                <router-link
                  :to="{ name: 'profile', query: { profile_id: state.post.user_id } }"
                >
                  <h5 class="author-mag_title" v-text="state.post.user_name"></h5>
                </router-link>
                <dl class="author-mag_tag">
                  <dd>Tác giả</dd>
                </dl>
              </div>
            </div>
            <div class="author-mag_follow">
              <FollowButton
                class="brand_follow-btn primary-button button"
                followable_type="user"
                :followable_id="state.author.user_id"
                :is_follow="state.author.is_follow"
              />
            </div>
          </div>

          <section>
            <div class="section-wrappers dosiin-comment-feed-detail-warpper">
              <div class="section-title-wrapper">
                <h2 class="section-title">Thảo luận bài viết</h2>
              </div>

              <template v-if="discussions.posts && discussions.posts.length">
                <div class="section-title-wrapper">
                  <h2 class="section-title">
                    <b>{{ state.post.discussion_count }}</b> thảo luận
                  </h2>
                  <button
                    class="dosiin_comment-sort comment-sort-btn d-flex align-items-center justify-content-between d-none"
                  >
                    Mới - Cũ<i class="dsi dsi-arrow-down dosiin_ml-2"></i>
                  </button>
                </div>

                <div class="comment-feed-detail-warpper customer-review-section">
                  <div
                    class="review-item review-item-product list-comment-feed-detail"
                    v-for="(discussion, i) in discussions.posts"
                    :key="i"
                  >
                    <div class="review-item-info">
                      <router-link
                        :to="{
                          name: 'profile',
                          query: { profile_id: discussion.user_id },
                        }"
                        class="link-item-product"
                      >
                        <img
                          v-lazy="_userAvatar(discussion.user_avatar)"
                          class="user-avatar"
                          width="40"
                          height="40"
                        />
                      </router-link>
                      <div class="rating-info">
                        <h3 class="user-name truncate" v-text="discussion.name"></h3>
                        <div class="rating-with-time">
                          <span
                            class="rating-time-product"
                            v-text="_momentFromNow(discussion.timestamp)"
                          ></span>
                        </div>
                        <div class="review-item-preview dosiin_mt-2">
                          <div class="review-item-preview_left">
                            <div class="review-item-description">
                              <span v-text="discussion.message"></span>
                            </div>
                          </div>
                        </div>
                        <div class="review-item-action">
                          <div class="review-item-action-btn">
                            <LikeButton
                              likeable_type="discussion"
                              :likeable_id="discussion.post_id"
                              :isLiked="discussion.is_like"
                            />

                            <button
                              class="more-btn dosiin_reply-btn comment"
                              @click="
                                $notify({
                                  type: 'vv',
                                  title: 'Thông báo',
                                  text: 'Tính năng đang phát triển.',
                                })
                              "
                            >
                              <i class="dsi dsi-more-h"></i>
                            </button>
                          </div>
                          <div class="review-item-action-info">
                            <span class="info-like"
                              >{{ discussion.like_count }} lượt thích</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div class="no_comments-inner" v-else>
                <div class="no_comments" style="text-align: center">
                  <img :src="_emptyReview" />
                  <p>Bài viết chưa có thảo luận</p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div class="link-group-action sticky" id="dosiin_footer-sticky">
          <CommentPost />
          <hr class="line" />
          <div class="review-item-action align-items-center">
            <div class="review-item-action-btn">
              <LikeButton
                likeable_type="vnfd"
                :likeable_id="state.post.vnfd_id"
                :isLiked="state.post.is_like"
                :indexPost="'post_' + state.post.vnfd_id"
              />

              <CommentButton
                isType="comment"
                :vnfd_id="state.post.vnfd_id"
                :url="state.post.seo_name"
              />

              <a
                class="social-interact-btn dosiin_social-interact-btn share"
                @click="sharePost(state.post)"
              >
                <i class="dsi dsi-share"></i>
              </a>
            </div>
            <div class="review-item-action-info">
              <span class="info-like"
                ><b>{{ state.post.like_count }} </b>&nbsp;lượt thích</span
              >
              <span class="dosiin_mr-2 dosiin_ml-2"> • </span>
              <span class="info-reply"
                ><b>{{ state.post.discussion_count }} </b>&nbsp;bình luận</span
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <ShareButtonModal ref="modalShare" />
  </main>
</template>
<script>
import { inject, ref } from "vue";
import CommentPost from "@/components/forms/CommentForm.vue";
import PostHeader from "@/components/post/Header.vue";
import PostSkeleton from "@/components/skeletons/PostLoader.vue";
import Hashtag from "@/components/hashtag/HashTag.vue";
export default {
  components: {
    CommentPost,
    PostHeader,
    PostSkeleton,
    Hashtag,
  },
  setup() {
    const state = inject("state");
    const discussions = inject("discussions");
    const modalShare = ref(null);
    function sharePost(post) {
      modalShare.value.updatePost(post);
    }

    return {
      state,
      modalShare,
      sharePost,
      discussions,
    };
  },
};
</script>

<style>
.desc img {
  width: 100% !important;
  height: auto;
}
.news-title {
  font-weight: 700;
  font-size: 1.6em;
  line-height: 1.2em;
  margin: 0;
  padding-top: 30px;
  margin-bottom: 1.2rem;
}

#firstContent {
  clear: both;
  display: block;
  visibility: visible;
  opacity: 1;
  position: relative;
  line-height: 30px;
}
.maz-description {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #101010;
}

.style-detail-post-mag .post-group .content h1.title {
  font-size: 30px !important;
  line-height: 120% !important;
}
</style>
