<template>
  <div
    class="box-content-post post_detail-wrapper post_detail-page dosiin_padding-header-top"
  >
    <div class="ds_post-mag-page">
      <div class="magazine-header">
        <h1 class="title-magazine" v-text="state.post.title"></h1>
        <div class="cate-time-line">
          <div class="time-tag">Tin tức</div>
          <div class="time-day-box">
            <i class="dsi dsi-clock"></i
            ><span v-text="_momentDayToDateString(state.post.timestamp)"></span>
          </div>
        </div>
      </div>
      <div
        id="news_content"
        class="magazine-content"
        v-html="state.post.description"
      ></div>
      <div class="magazine-comment">
        <div class="magazine-comment-left">
          <CommentFormV2Vue />
          <div class="section-title-wrapper">
            <h2 class="section-title">Thảo luận bài viết</h2>
          </div>

          <template v-if="discussions.posts && discussions.posts.length">
            <div
              class="list-munti-review brand-review-item"
              v-for="(discussion, i) in discussions.posts"
              :key="i"
            >
              <div class="review-item review-item-product">
                <div class="review-item-info">
                  <router-link
                    :to="{
                      name: 'profile',
                      query: { profile_id: discussion.user_id },
                    }"
                    class="link-item-product no-short"
                  >
                    <img
                      v-lazy="_userAvatar(discussion.user_avatar)"
                      class="user-avatar"
                      width="40"
                      height="40"
                    />
                  </router-link>
                  <div class="rating-info">
                    <h3 class="user-name truncate" v-text="discussion.name"></h3>
                    <div class="rating-with-time"></div>
                    <span
                      class="rating-time-product rating-time-product-txt"
                      v-text="_momentFromNow(discussion.timestamp)"
                    ></span>
                    <div class="review-item-preview"></div>
                    <div class="review-item-preview_left">
                      <div class="review-item-description"></div>
                      <span v-text="discussion.message"></span>
                    </div>
                    <div class="review-item-action dosiin_mt-12">
                      <div class="review-item-action-btn">
                        <LikeButton
                          likeable_type="discussion"
                          :likeable_id="discussion.post_id"
                          :isLiked="discussion.is_like"
                        />

                        <button
                          class="more-btn dosiin_reply-btn comment dosiin_ml-1"
                          @click="
                            $notify({
                              type: 'vv',
                              title: 'Thông báo',
                              text: 'Tính năng đang phát triển.',
                            })
                          "
                        >
                          <i class="dsi dsi-more-h"></i>
                        </button>
                      </div>
                      <div class="review-item-action-info">
                        <span class="info-like"></span
                        ><b>{{ discussion.like_post }} </b> lượt thích
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="no_comments-inner" v-else>
            <div class="no_comments" style="text-align: center">
              <img :src="_emptyReview" />
              <p>Bài viết chưa có thảo luận</p>
            </div>
          </div>
        </div>
        <div class="line-magazine"></div>
        <div class="magazine-comment-right">
          <div class="mazine-author" v-if="state.post.user_id != 0">
            <h4>TÁC GIẢ</h4>
            <router-link
              class="mazine-author-box"
              :to="{ name: 'profile', query: { profile_id: state.post.user_id } }"
            >
              <div class="mazine-author-info"></div>
              <img
                width="40"
                height="40"
                v-lazy="{
                  src: _userAvatar(state.author.avatar),
                  loading: _userDefaultAvatar,
                  error: _userDefaultAvatar,
                }"
              />
              <div class="mazine-author-txt">
                <h3 v-text="state.post.user_name"></h3>
                <p class="dosiin_mt-1"></p>
                <b v-text="state.author.follow_count"></b>&nbsp;người theo dõi
              </div>
              <i class="dsi dsi-chevron-right"> </i>
            </router-link>
            <span class="dosiin-hidden">{{ state.author.user_id }}</span>
            <FollowButton
              class="brand_follow-btn primary-button button"
              followable_type="user"
              :followable_id="state.author.user_id"
              :is_follow="state.author.is_follow"
            />
          </div>

          <div class="mazine-author">
            <h4>CHI TIẾT BÀI VIẾT</h4>
            <div class="mazine-total">
              <div class="mazine-total-item">
                <h5 v-text="state.post.popularity"></h5>
                <p>Lượt xem</p>
              </div>
              <div class="mazine-total-item">
                <h5 v-text="state.post.like_count"></h5>
                <p>Lượt thích</p>
              </div>
              <div class="mazine-total-item">
                <h5 v-text="state.post.discussion_count"></h5>
                <p>Thảo luận</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentPost from "@/components/forms/CommentForm.vue";
import PostHeader from "@/components/post/Header.vue";
import PostSkeleton from "@/components/skeletons/PostLoader.vue";
import Hashtag from "@/components/hashtag/HashTag.vue";
import { ref, inject } from "vue";
import { Navigation, Lazy } from "swiper";
import RelatedPost from "@/components/post/RelatedPost";
import CommentFormV2Vue from "@/components/forms/CommentFormV2.vue";
export default {
  components: {
    CommentPost,
    PostHeader,
    PostSkeleton,
    Hashtag,
    RelatedPost,
    CommentFormV2Vue,
  },
  setup() {
    const state = inject("state");
    const sortDiscussion = inject("sortDiscussion");
    const discussions = inject("discussions");
    const dropdownShare = ref(null);
    const swiperOptions = {
      slideToClickedSlide: true,
      watchSlidesVisibility: false,
      watchSlidesProgress: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    const sharePostDropdown = (post, event) => {
      dropdownShare.value.updatePost(post, event);
    };

    return {
      state,
      discussions,
      sortDiscussion,
      swiperOptions,
      sharePostDropdown,
      modules: [Navigation, Lazy],
    };
  },
};
</script>

<style>
.desc img {
  width: 100% !important;
  height: auto;
}
.news-title {
  font-weight: 700;
  font-size: 1.6em;
  line-height: 1.2em;
  margin: 0;
  padding-top: 30px;
  margin-bottom: 1.2rem;
}

#firstContent {
  clear: both;
  display: block;
  visibility: visible;
  opacity: 1;
  position: relative;
  line-height: 30px;
}

.scroll-bar-wrap .scroll-box {
  padding-bottom: 90px;
}

.ds_post-mag-page {
  border-radius: 8px;
}
.magazine-comment-left .no-login {
  background: #ebebeb;
  border: 1px solid rgba(16, 16, 16, 0.1);
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
}
</style>
