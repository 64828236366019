<template>
  <div
    class="box-content-post post_detail-wrapper post_detail-page dosiin_padding-header-top"
  >
    <div v-if="!dosiinEmptyObject(state.post)">
      <div class="post-group black-post overflow post_detail-inner">
        <div class="dosiin_post-img-slider post-img-slider post_detail-left">
          <div class="swiper-button-next dosiin_swiper-button-gradient"></div>
          <div class="swiper-button-prev dosiin_swiper-button-gradient"></div>
          <Swiper
            :modules="modules"
            :slideToClickedSlide="swiperOptions.slideToClickedSlide"
            :watchSlidesVisibility="swiperOptions.watchSlidesVisibility"
            :watchSlidesProgress="swiperOptions.watchSlidesProgress"
            :navigation="swiperOptions.navigation"
            :preloadImages="false"
            :lazy="true"
          >
            <SwiperSlide class="img-post-wrapper dosiin_post-detail">
              <img v-lazy="_postImage(state.post.main_pair)" class="dosiin_w-100" />
            </SwiperSlide>
            <SwiperSlide
              class="img-post-wrapper dosiin_post-detail"
              v-for="(img, i) in state.post.addition_images"
              :key="i"
            >
              <img v-lazy="_postImageWithFullUrl(img)" class="dosiin_w-100 swiper-lazy" />
              <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div class="post-group-info post_detail-right">
          <div class="content-post-right">
            <PostHeader
              :post="state.post"
              :show_back_button="false"
              :showSkeletor="true"
            />
            <div class="scroll-bar-wrap">
              <div class="scroll-box">
                <div class="content">
                  <div class="tag-link" v-if="state.post.tags">
                    <router-link
                      v-for="(tag, key) in state.post.tags"
                      :to="{ name: 'tags', query: { tag: tag.tag } }"
                      v-bind:key="key"
                      >#{{ $filters.trim(tag.tag) }}&nbsp;
                    </router-link>
                  </div>
                </div>

                <section id="firstContent">
                  <div class="desc" v-text="state.post.short_description"></div>
                </section>

                <div class="box-product-tag">
                  <div
                    class="list-item product-list-item"
                    v-for="(product, p_id) in state.post.products"
                    v-bind:key="p_id"
                  >
                    <router-link
                      class="list-item_img"
                      :to="_productUrl(product)"
                      :title="product.product"
                    >
                      <img
                        class="product-sm-item_img"
                        v-lazy="_productThumbnail(product.main_pair)"
                        :alt="product.product"
                        :title="product.product"
                        width="76"
                        height="76"
                      />
                    </router-link>
                    <dl class="list-item_summary">
                      <div class="product-list-item_name_wrapper">
                        <dd class="list-item_name">
                          <router-link
                            class="list-item_product"
                            :to="_productUrl(product)"
                            :title="product.product"
                          >
                            {{ product.product }}</router-link
                          >
                        </dd>
                      </div>
                      <div class="list-item_sale product-list-item_price_wrapper">
                        <dd class="list-item_price">
                          {{ currencyFormat(product.price) }}
                        </dd>
                        <dd
                          class="inline-bl list-item_sale_origin"
                          v-if="product.list_price > product.price"
                        >
                          {{ currencyFormat(product.list_price) }}
                        </dd>
                        <dd
                          class="inline-bl list-item_sale_discount"
                          v-if="discountPrc(product) > 0"
                        >
                          {{ discountPrc(product) }}%
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>

                <div v-if="state.post.categories_brands">
                  <div class="dosiin-tag-properties">
                    <dl
                      class="tag-list-item"
                      v-for="(cat_with_name, index) in state.post
                        .categories_brands_with_name"
                      v-bind:key="index"
                    >
                      <dt class="tag-icon dosiin_mr-2">
                        <i :class="['dsi', getCategoryIcon(index)]"></i>
                      </dt>
                      <dd class="tag-name">
                        <div class="dosiin_hashtag_slider swiper-tag-name-detail-feed">
                          <div class="swiper-wrapper">
                            <div
                              class="swiper-slide"
                              v-for="(brand_name, i) in cat_with_name.brand_name"
                              :key="i"
                            >
                              <a href="#" :title="brand_name">
                                <data class="tag-name-item dosiin_mr-1"
                                  >#{{ brand_name }}</data
                                >
                              </a>
                            </div>
                          </div>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>

                <div class="section-wrappers dosiin-comment-feed-detail-warpper">
                  <div class="section-title-wrapper">
                    <h2 class="section-title">Thảo luận bài viết</h2>
                  </div>
                  <template v-if="discussions.posts && discussions.posts.length">
                    <div class="section-title-wrapper">
                      <h2 class="section-title1">
                        <b>{{ state.post.discussion_count }} </b> <span> thảo luận</span>
                      </h2>
                      <button
                        class="dosiin_comment-sort comment-sort-btn d-flex align-items-center justify-content-between d-none"
                      >
                        Mới - Cũ<i class="dsi dsi-arrow-down dosiin_ml-2"></i>
                      </button>
                    </div>

                    <div
                      class="comment-feed-detail-warpper customer-review-section dosiin_pt-0 dosiin_pl-0 dosiin_pr-0"
                    >
                      <div
                        class="review-item review-item-product list-comment-feed-detail"
                        v-for="(discussion, i) in discussions.posts"
                        :key="i"
                      >
                        <div class="review-item-info">
                          <router-link
                            :to="{
                              name: 'profile',
                              query: { profile_id: discussion.user_id },
                            }"
                            class="link-item-product"
                          >
                            <img
                              v-lazy="_userAvatar(discussion.user_avatar)"
                              class="user-avatar"
                              width="40"
                              height="40"
                            />
                          </router-link>
                          <div class="rating-info">
                            <h3 class="user-name truncate" v-text="discussion.name"></h3>
                            <div class="rating-with-time">
                              <span
                                class="rating-time-product"
                                v-text="_momentFromNow(discussion.timestamp)"
                              ></span>
                            </div>
                            <div class="review-item-preview dosiin_mt-2">
                              <div class="review-item-preview_left">
                                <div class="review-item-description">
                                  <span v-text="discussion.message"></span>
                                </div>
                              </div>
                            </div>
                            <div class="review-item-action d-none">
                              <div class="review-item-action-btn">
                                <LikeButton
                                  likeable_type="discussion"
                                  :likeable_id="discussion.post_id"
                                  :isLiked="discussion.is_like"
                                />

                                <button
                                  class="more-btn dosiin_reply-btn comment"
                                  @click="
                                    $notify({
                                      type: 'vv',
                                      title: 'Thông báo',
                                      text: 'Tính năng đang phát triển.',
                                    })
                                  "
                                >
                                  <i class="dsi dsi-more-h"></i>
                                </button>
                              </div>
                              <div class="review-item-action-info">
                                <span class="info-like"
                                  >{{ discussion.like_count }} lượt thích</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <div class="no_comments-inner" v-else>
                    <div class="no_comments">
                      <img :src="_emptyReview" />
                      <p>Bài viết chưa có thảo luận</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="link-group-action" id="dosiin_footer-sticky">
                <CommentPost />
                <hr class="line" />
                <div class="review-item-action align-items-center dosiin_pd-016">
                  <div class="review-item-action-btn">
                    <LikeButton
                      likeable_type="vnfd"
                      :likeable_id="state.post.vnfd_id"
                      :isLiked="state.post.is_like"
                      :indexPost="'post_' + state.post.vnfd_id"
                    />

                    <button
                      class="share_dropdow dosiin_ml-2"
                      @click="sharePostDropdown(state.post, $event)"
                    >
                      <i class="dsi dsi-share"></i>
                    </button>
                  </div>
                  <div class="review-item-action-info">
                    <span class="info-like"
                      ><b>{{ state.post.like_count }} </b>&nbsp;lượt thích</span
                    >
                    <span class="dosiin_mr-2 dosiin_ml-2"> • </span>
                    <span class="info-reply"
                      ><b>{{ state.post.discussion_count }} </b>&nbsp;bình luận</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="line_post" />
      <RelatedPost :post="state.post" key="te" />
      <ShareButtonDropdown ref="dropdownShare" />
    </div>

    <LoadingComponent v-else />
  </div>
</template>

<script>
import CommentPost from "@/components/forms/CommentForm.vue";
import PostHeader from "@/components/post/Header.vue";
import PostSkeleton from "@/components/skeletons/PostLoader.vue";
import Hashtag from "@/components/hashtag/HashTag.vue";
import { ref, inject } from "vue";
import { Navigation, Lazy } from "swiper";
import { _dsEmptyObject } from "@/utlis/helpers";
import RelatedPost from "@/components/post/RelatedPost";
export default {
  components: {
    CommentPost,
    PostHeader,
    PostSkeleton,
    Hashtag,
    RelatedPost,
  },
  setup() {
    const state = inject("state");
    const sortDiscussion = inject("sortDiscussion");
    const discussions = inject("discussions");
    const dropdownShare = ref(null);
    const swiperOptions = {
      slideToClickedSlide: true,
      watchSlidesVisibility: false,
      watchSlidesProgress: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    const sharePostDropdown = (post, event) => {
      dropdownShare.value.updatePost(post, event);
    };

    return {
      state,
      discussions,
      sortDiscussion,
      swiperOptions,
      sharePostDropdown,
      dropdownShare,
      modules: [Navigation, Lazy],
    };
  },
};
</script>

<style>
.desc img {
  width: 100% !important;
  height: auto;
}
.news-title {
  font-weight: 700;
  font-size: 1.6em;
  line-height: 1.2em;
  margin: 0;
  padding-top: 30px;
  margin-bottom: 1.2rem;
}

#firstContent {
  clear: both;
  display: block;
  visibility: visible;
  opacity: 1;
  position: relative;
  line-height: 30px;
}

.scroll-bar-wrap .scroll-box {
  padding-bottom: 90px;
}
</style>
