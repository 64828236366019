<template>
  <PostMB v-if="layout == 4" />
  <PostPC v-else />
</template>

<script>
import PostMB from "@/pages/mb/post/Post";
import PostPC from "@/pages/pc/post/Post";
import StyleService from "@/services/style";
import DiscussionService from "@/services/discussion";
import UserService from "@/services/user";
import { reactive, provide, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { _dsEmptyObject } from "@/utlis/helpers";

export default {
  props: {
    vParams: {
      type: Object,
    },
  },
  components: {
    PostMB,
    PostPC,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      vnfd_id: props.vParams.vid,
      post: {},
      author: {},
      skeleton: true,
    });

    const getDiscussion = store.getters["PostsStore/getDiscussion"];
    const discussions = computed(() => getDiscussion(state.vnfd_id));

    getPostDetail();

    async function getPostDetail() {
      const response = await StyleService.fetchPost(state.vnfd_id, {
        timestamp: new Date().getTime(),
      });

      if (response && response.data) {
        state.post = response.data;
        store.dispatch("PostsStore/addPosts", { postData: [response.data] });
        // set title
        document.title = `Dosiin Style - Bài viết đăng bởi ${state.post.user_name}`;

        // get author

        // get discussion
        let paramsDiscussion = {
          vnfd_id: state.vnfd_id,
          params: {
            object_id: state.vnfd_id,
            type: "V",
            timestamp: new Date().getTime(),
          },
        };

        getDisucssion(paramsDiscussion);

        if (state.post.user_id != 0) {
          getAuthor(state.post.user_id);
        }
      }

      state.skeleton = false;
    }

    async function getDisucssion({ vnfd_id, params }) {
      const response = await DiscussionService.fetchPostDiscussion(vnfd_id, params);
      store.commit("PostsStore/PUSH_DATA_DISCUSSIONS", {
        data: response.data,
        vnfd_id,
      });
    }

    async function getAuthor(user_id) {
      const response = await UserService.fetchUser(user_id);
      if (response && response.data) {
        state.author = response.data;
      }
    }

    const sortDiscussion = (event, vnfd_id) => {
      let button = event.target;
      let sort_order = "desc";

      if (button.classList.contains("dsi-drop-down")) {
        sort_order = "desc";
        button.classList.remove("dsi-drop-down");
        button.classList.add("dsi-drop-up");
      } else {
        sort_order = "asc";
        button.classList.remove("dsi-drop-up");
        button.classList.add("dsi-drop-down");
      }

      store.commit("PostsStore/CHANGE_DISCUSSION_SORT ", {
        sort_by: "timestamp",
        sort_order: sort_order,
        vnfd_id: vnfd_id,
      });
    };

    watch(
      () => route.params,
      async (params) => {
        if (_dsEmptyObject(params) || typeof params.vid == "undefined") {
          return;
        }
        state.vnfd_id = params.vid;
        state.post = {};
        getPostDetail();
      }
    );

    provide("state", state);
    provide("sortDiscussion", sortDiscussion);
    provide("discussions", discussions);
  },
};
</script>
